import { Button, Center, StackDivider, VStack } from '@chakra-ui/react';

// import { PostItem } from 'components/shared';
import { useWpInfiniteItems } from 'hooks';
import { memo } from 'react';
import { getAcfBlockFetchData } from 'utls';

import dynamic from 'next/dynamic';
const PostItem = dynamic(() => import('../../shared/PostItem'));
const WideCards = props => {
  const { attrs, posts } = props;

  if (posts?.length === 0) {
    return null;
  }

  // const { data } = attrs;

  console.log(props, 'propsprops');

  // const { name, params } = getAcfBlockFetchData({
  //   blockName,
  //   ...data
  // });

  // const { items, hasMore, isLoading, loadMore } = useWpInfiniteItems(
  //   name,
  //   params,
  //   initialData
  // );

  // const { enable_pagination } = data;

  // const hasLoadMore = hasMore && enable_pagination === '1';

  return (
    <>
      <VStack
        w='full'
        align={'stretch'}
        spacing={4}
        divider={<StackDivider borderColor='gray.200' />}
        borderBottomWidth={1}
        borderBottomColor='gray.200'
        py='4'
      >
        {Array.isArray(posts) &&
          posts.map(item => <PostItem key={item?.id} {...item} />)}
        {/*  {hasLoadMore && (
          <Center>
            <Button
              onClick={loadMore}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              المزيد
            </Button>
          </Center>
        )} */}
      </VStack>
    </>
  );
};

export default memo(WideCards);
